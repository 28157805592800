<template>
    <div class="button-group"
      :class="{'button-group--equals-gup': equalsGup, 'button-group--vertical': isVertical}"
    >
      <slot></slot>
    </div>
</template>

<script>
export default {
  name: 'base-button-group',
  props: {
    equalsGup: {
      type: Boolean,
      default: false
    },
    isVertical: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <fragment>
  <footer-link-list>
    <footer-block-contacts-item v-for="(item, index) in contactList"
                                :key="index"
                                :index="index"
    />
  </footer-link-list>

    <div class="section-footer__social-links-list" v-if="socialList.length">
      <FooterBlockContactsSocialsItem v-for="(item, index) in socialList"
                                      :key="index"
                                      :index="index"/>
    </div>
  </fragment>
</template>

<script>
import FooterBlockContactsItem from '@/components/section/footer/components/FooterBlockContactsItem'
import FooterBlockContactsSocialsItem from '@/components/section/footer/components/FooterBlockContactsSocialsItem'
import FooterLinkList from '@/components/section/footer/components/FooterLinkList'

export default {
  name: 'FooterBlockContacts',
  components: {
    FooterBlockContactsSocialsItem,
    FooterBlockContactsItem,
    FooterLinkList
  },
  computed: {
    contactList () {
      return this.$t('PORTAL.SECTION.FOOTER.CONTACTS.VALUES')
    },
    socialList () {
      return this.$t('PORTAL.SECTION.FOOTER.SOCIAL.LINKS.VALUES')
    }
  }
}
</script>

<template>
  <base-card-list class="section-footer__cookie-info">
    <base-card is-no-shadow>
      <span>При входе на сайт вы принимаете пользовательское соглашение. Мы используем файлы cookie в соответствии с пользовательским соглашением в отношении файлов cookie, чтобы обеспечить лучшую работу с сайтом. Продолжая пользоваться сайт, вы даете согласие на использование этих технологий. Републикация новостных материалов свободна</span>
    </base-card>
  </base-card-list>
</template>

<script>
import BaseCard from '@/components/base/card/BaseCard'
import BaseCardList from '@/components/base/card/BaseCardList'
export default {
  name: 'SectionFooterCookieInfo',
  components: { BaseCard, BaseCardList }
}
</script>

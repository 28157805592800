<template>
<router-link :to="{path: '#'}"
             :href="getUrl">
  <base-icon :icon="getIcon"/>
</router-link>
</template>

<script>
import BaseIcon from '@/components/base/icon/BaseIcon'
export default {
  name: 'FooterBlockContactsSocialsItem',
  components: { BaseIcon },
  props: {
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    getUrl () {
      return this.$t(`PORTAL.SECTION.FOOTER.SOCIAL.LINKS.VALUES[${this.index}].URL`)
    },
    getIcon () {
      return this.$t(`PORTAL.SECTION.FOOTER.SOCIAL.LINKS.VALUES[${this.index}].ICON`)
    }
  }
}
</script>

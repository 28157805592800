<template>
  <header class="head">
    <base-company-logo @click.native="$router.push({name: $options.ROUTE_INDEX_PAGE})"/>
    <section-head-city-picker v-if="!onlyLogo"/>
    <base-icon-text
      v-if="!onlyLogo"
      class="head__contact"
      :icon="$t('PORTAL.SERVICE.HEAD.PHONE.ICON')">
      {{$t('PORTAL.SERVICE.HEAD.PHONE.VALUE') | headPhone}}
    </base-icon-text>
    <base-button-group
      class="head__buttons"
      equals-gup
      v-if="!onlyLogo"
    >
      <base-button
        class="button__qa"
        :icon="$t('PORTAL.BUTTONS.ASK_SUPPORT.ICON')"
        accent
        bordered
      >
        <span> {{$t('PORTAL.BUTTONS.ASK_SUPPORT.VALUE')}} </span>
      </base-button>
      <base-button
        class="button__auth"
        :icon="$t('PORTAL.BUTTONS.LOGIN.ICON')"
        bordered
      >
        <span> {{$t('PORTAL.BUTTONS.LOGIN.VALUE')}}</span>
      </base-button>
    </base-button-group>
  </header>
</template>

<script>
import BaseButton from '@/components/base/button/BaseButton'
import BaseButtonGroup from '@/components/base/button/BaseButtonGroup'
import BaseCompanyLogo from '@/components/base/companyLogo/BaseCompanyLogo'
import BaseIconText from '@/components/base/iconText/BaseIconText'
import SectionHeadCityPicker from '@/components/section/head/SectionHeadCityPicker'
import { C_ROUTER_NAME_INDEX } from '@/lib/constant/c.router'

export default {
  name: 'SectionHead',
  ROUTE_INDEX_PAGE: C_ROUTER_NAME_INDEX,
  components: {
    SectionHeadCityPicker,
    BaseButton,
    BaseButtonGroup,
    BaseIconText,
    BaseCompanyLogo
  },
  props: {
    onlyLogo: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <footer-link-list-item :href="getUrl">
    <base-icon-text :icon="getIcon">{{displayValue}}</base-icon-text>
  </footer-link-list-item>
</template>

<script>
import BaseIconText from '@/components/base/iconText/BaseIconText'
import FooterLinkListItem from '@/components/section/footer/components/FooterLinkListItem'
import { filtersPhoneActualFormat } from '@/lib/filters'

export default {
  name: 'FooterBlockContactsItem',
  components: { BaseIconText, FooterLinkListItem },
  props: {
    index: {
      type: Number
    }
  },
  computed: {
    getValue () {
      return this.$t(`PORTAL.SECTION.FOOTER.CONTACTS.VALUES[${this.index}].VALUE`)
    },
    displayValue () {
      const value = this.getValue
      if (this.getUrl.includes('tel:')) {
        return filtersPhoneActualFormat(value)
      }
      return value
    },
    getUrl () {
      return this.$t(`PORTAL.SECTION.FOOTER.CONTACTS.VALUES[${this.index}].URL`, { value: this.getValue })
    },
    getIcon () {
      return this.$t(`PORTAL.SECTION.FOOTER.CONTACTS.VALUES[${this.index}].ICON`)
    }
  }
}
</script>

<template>
<li>
  <router-link :to="to" :href="href"><slot></slot></router-link>
</li>
</template>

<script>
export default {
  name: 'FooterLinkListItem',
  props: {
    to: {
      type: Object,
      default: () => {
        return { path: '#' }
      }
    },
    href: {
      type: String
    }
  }
}
</script>

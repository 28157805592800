<template>
  <base-section class="section-footer">
    <section-footer-actual-link-list/>
    <section-footer-normative-documents/>
    <section-footer-agreement-list/>
    <section-footer-cookie-info/>
    <section-footer-functional-list/>
    <section-footer-item-footer/>
  </base-section>
</template>

<script>
import BaseSection from '@/components/base/section/BaseSection'
import SectionFooterActualLinkList from '@/components/section/footer/SectionFooterActualLinkList'
import SectionFooterAgreementList from '@/components/section/footer/SectionFooterAgreementList'
import SectionFooterCookieInfo from '@/components/section/footer/SectionFooterCookieInfo'
import SectionFooterFunctionalList from '@/components/section/footer/SectionFooterFunctionalList'
import SectionFooterItemFooter from '@/components/section/footer/SectionFooterItemFooter'
import SectionFooterNormativeDocuments from '@/components/section/footer/SectionFooterNormativeDocuments'

export default {
  name: 'SectionFooter',
  components: {
    SectionFooterItemFooter,
    SectionFooterFunctionalList,
    SectionFooterCookieInfo,
    SectionFooterAgreementList,
    SectionFooterNormativeDocuments,
    SectionFooterActualLinkList,
    BaseSection
  }
}
</script>

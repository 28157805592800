<template>
  <base-icon-text
    class="city-select head__city-select"
    :icon="$t('PORTAL.HEAD.CITY-SELECTED.ICON')">
    {{$t('PORTAL.HEAD.CITY-SELECTED.VALUE', {value: getCityValue})}}
  </base-icon-text>
</template>

<script>
import BaseIconText from '@/components/base/iconText/BaseIconText'
import { GEO_GETTER_GET_CITY } from '@/store/modules/geo/geo.constant'
import { STORE_PATH_GEO } from '@/store/store.list'
import { mapGetters } from 'vuex'
export default {
  name: 'CityPicker',
  components: { BaseIconText },
  computed: {
    ...mapGetters(STORE_PATH_GEO, [GEO_GETTER_GET_CITY]),
    getCityValue () {
      const city = this[GEO_GETTER_GET_CITY]
      if (typeof city === 'undefined') {
        return this.$t('PORTAL.HEAD.CITY-SELECTED.DEFAULT.VALUE')
      } else {
        return city
      }
    }
  }
}
</script>

<template>
  <base-card-list class="section-footer__normative-list">
    <base-loader
      is-relative
      v-if="isLoading"/>
    <base-card v-else
               is-no-shadow
               v-for="(documents, index) in getDocumentsParts"
               :key="index">
      <template v-slot:card-title>

        <h6 v-if="index === 0">{{$t('PORTAL.SECTION.FOOTER.NORMATIVE-DOCUMENTS.HEADER.VALUE')}}</h6>
        <h6 v-else v-html="'&nbsp;'"></h6>
      </template>
        <footer-link-list>
          <footer-link-list-item
            v-for="document in documents"
            :key="document.id"
            :href="document.file.url">
            {{document.name}}
          </footer-link-list-item>
        </footer-link-list>

    </base-card>
  </base-card-list>
</template>

<script>
import BaseCard from '@/components/base/card/BaseCard'
import BaseCardList from '@/components/base/card/BaseCardList'
import BaseLoader from '@/components/base/loader/BaseLoader'
import FooterLinkList from '@/components/section/footer/components/FooterLinkList'
import FooterLinkListItem from '@/components/section/footer/components/FooterLinkListItem'
import { COMMON_LOADER_GETTER_IS_LOADING } from '@/store/modules/common/loader/common.loader.constant'
import {
  SYSTEM_GETTER_GET_NORMATIVE_DOCUMENTS_LIST,
  SYSTEM_ACTION_FETCH_NORMATIVE_DOCUMENTS
} from '@/store/modules/system/system.constant'
import { STORE_PATH_SYSTEM } from '@/store/store.list'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SectionFooterNormativeDocuments',
  components: {
    BaseLoader,
    FooterLinkListItem,
    FooterLinkList,
    BaseCard,
    BaseCardList
  },
  computed: {
    ...mapGetters(STORE_PATH_SYSTEM, [SYSTEM_GETTER_GET_NORMATIVE_DOCUMENTS_LIST, COMMON_LOADER_GETTER_IS_LOADING]),
    getDocumentsParts () {
      let i = this[SYSTEM_GETTER_GET_NORMATIVE_DOCUMENTS_LIST].length
      const middle = Math.ceil(i / 2)
      const result = [[], []]
      while (i--) {
        const el = this[SYSTEM_GETTER_GET_NORMATIVE_DOCUMENTS_LIST][i]
        const pi = i >= middle ? 1 : 0
        result[pi].unshift(el)
      }
      return result
    },
    isLoading () {
      return this[COMMON_LOADER_GETTER_IS_LOADING]
    }
  },
  async mounted () {
    await this.getItems()
  },
  methods: {
    ...mapActions(STORE_PATH_SYSTEM, [SYSTEM_ACTION_FETCH_NORMATIVE_DOCUMENTS]),
    async getItems () {
      if (this[SYSTEM_GETTER_GET_NORMATIVE_DOCUMENTS_LIST].length === 0) {
        await this[SYSTEM_ACTION_FETCH_NORMATIVE_DOCUMENTS]()
      }
    }
  }
}
</script>

<template>
  <footer>
    <base-company-logo/>
    <div class="footer__copy">
      <span v-html="$t('PORTAL.SECTION.FOOTER.FOOTER-ITEM.COPY', {now: getNowYear})"></span>
    </div>
    <div class="footer__creator">
      <div class="logo">
        <img src="../../../assets/img/copy.png"/>
      </div>
      <div class="footer__creator-content">
        <span>Разработано в студии</span>
        <span>Артема Лебедева</span>
      </div>
    </div>
  </footer>
</template>

<script>
import BaseCompanyLogo from '@/components/base/companyLogo/BaseCompanyLogo'
export default {
  name: 'SectionFooterItemFooter',
  components: { BaseCompanyLogo },
  computed: {
    getNowYear () {
      return (new Date()).getFullYear()
    }
  }
}
</script>

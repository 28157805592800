<template>
  <city-picker/>
</template>

<script>
import CityPicker from '@/components/misc/CityPicker'
export default {
  name: 'SectionHeadCityPicker',
  components: { CityPicker }
}
</script>

<template>
  <base-card-list class="section-footer__functional-list">
    <base-card is-no-shadow>
      <div class="functional-list__store-list">
        <img src="../../../assets/img/app-store.png"
             :alt="$t('PORTAL.SECTION.FOOTER.FUNCTIONAL-LIST.STORE.APP-STORE.ALT')">
        <img src="../../../assets/img/google-play.png"
             :alt="$t('PORTAL.SECTION.FOOTER.FUNCTIONAL-LIST.STORE.GOOGLE-PLAY.ALT')">
      </div>
    </base-card>
    <base-card is-no-shadow class="functional-list__subscribe">
      <base-form-text-input :placeholder="$t('PORTAL.SECTION.FOOTER.FUNCTIONAL-LIST.SUBSCRIBE.INPUT.PLACEHOLDER')"
                       :icon="$t('PORTAL.SECTION.FOOTER.FUNCTIONAL-LIST.SUBSCRIBE.INPUT.ICON')"
                       is-email
                       v-model="email"/>
      <span class="hint">{{$t('PORTAL.SECTION.FOOTER.FUNCTIONAL-LIST.SUBSCRIBE.INPUT.HINT')}}</span>
    </base-card>
    <base-card is-no-shadow>
      <base-button icon="chat"
                   :to="{path:'#'}"
                   accent
                   bordered> {{$t('PORTAL.BUTTONS.ASK_SUPPORT.VALUE')}}
      </base-button>
    </base-card>
    <base-card is-no-shadow class="functional-list__orders-button-group">
      <base-button-group class=""
                         is-vertical
                         equals-gup>
        <base-button :to="{path:'#'}"
                     bordered
                     is-fill>
          <span>{{$t('PORTAL.SECTION.FOOTER.FUNCTIONAL-LIST.ORDER.BUTTON.INSTITUTIONS.VALUE')}}</span>
          <span>{{$t('PORTAL.SECTION.FOOTER.FUNCTIONAL-LIST.ORDER.BUTTON.INSTITUTIONS.DESCRIPTION')}}</span></base-button>
        <base-button :to="{path:'#'}"
                     accent
                     bordered
                     is-fill>
          {{$t('PORTAL.SECTION.FOOTER.FUNCTIONAL-LIST.ORDER.BUTTON.TEACHER.VALUE')}}
          {{$t('PORTAL.SECTION.FOOTER.FUNCTIONAL-LIST.ORDER.BUTTON.TEACHER.DESCRIPTION')}}
        </base-button>
      </base-button-group>
    </base-card>
  </base-card-list>
</template>

<script>
import BaseButton from '@/components/base/button/BaseButton'
import BaseButtonGroup from '@/components/base/button/BaseButtonGroup'
import BaseCard from '@/components/base/card/BaseCard'
import BaseCardList from '@/components/base/card/BaseCardList'
import BaseFormTextInput from '@/components/base/form/input/BaseFormTextInput'
export default {
  name: 'SectionFooterFunctionalList',
  components: { BaseButton, BaseButtonGroup, BaseFormTextInput, BaseCard, BaseCardList },
  data () {
    return {
      email: ''
    }
  }
}
</script>

<template>
    <div class="logo logo__company">
      <img src="@/assets/logo.png"/>
    </div>
</template>

<script>
export default {
  name: 'BaseCompanyLogo'
}
</script>

<template>
  <base-card-list class="section-footer__agreements-list">
    <base-card is-no-shadow>
      <template
        v-slot:card-title>
        <h6>
          <router-link :to="{path:'#'}">{{$t('PORTAL.SECTION.FOOTER.AGREEMENT.HEADERS.CONFIDENTIALLY-POLICY.VALUE')}}</router-link>
        </h6>
      </template>
    </base-card>
    <base-card is-no-shadow>
      <template
        v-slot:card-title>
        <h6>
          <router-link :to="{path:'#'}">{{$t('PORTAL.SECTION.FOOTER.AGREEMENT.HEADERS.USER-AGREEMENT.VALUE')}}</router-link>
        </h6>
      </template>
    </base-card>
  </base-card-list>
</template>

<script>
// todo: Тут еще нужно указать ссылки либо на файлы либо на разделы хз пока
import BaseCard from '@/components/base/card/BaseCard'
import BaseCardList from '@/components/base/card/BaseCardList'
export default {
  name: 'SectionFooterAgreementList',
  components: { BaseCard, BaseCardList }
}
</script>

<template>
  <base-card-list class="section-footer__actual-link-list">
    <base-card is-no-shadow>
      <template v-slot:card-title>
        <h6>{{$t('PORTAL.SECTION.FOOTER.ACTUAL.LINK.HEADERS.INSTITUTION')}}</h6>
      </template>
      <footer-link-list>
        <footer-link-list-item>Ссылка 1</footer-link-list-item>
        <footer-link-list-item>Ссылка 2</footer-link-list-item>
        <footer-link-list-item>Ссылка 3</footer-link-list-item>
        <footer-link-list-item>Ссылка 4</footer-link-list-item>
      </footer-link-list>
    </base-card>
    <base-card is-no-shadow>
      <template v-slot:card-title>
        <h6>{{$t('PORTAL.SECTION.FOOTER.ACTUAL.LINK.HEADERS.TEACHER')}}</h6>
      </template>
      <footer-link-list>
        <footer-link-list-item>Ссылка 1</footer-link-list-item>
        <footer-link-list-item>Ссылка 2</footer-link-list-item>
        <footer-link-list-item>Ссылка 3</footer-link-list-item>
        <footer-link-list-item>Ссылка 4</footer-link-list-item>
        <footer-link-list-item>Ссылка 5</footer-link-list-item>
        <footer-link-list-item>Ссылка 6</footer-link-list-item>
        <footer-link-list-item>Ссылка 7</footer-link-list-item>
      </footer-link-list>
    </base-card>
    <base-card is-no-shadow>
      <template v-slot:card-title>
        <h6>{{$t('PORTAL.SECTION.FOOTER.ACTUAL.LINK.HEADERS.USER')}}</h6>
      </template>
      <footer-link-list>
        <footer-link-list-item>Ссылка 1</footer-link-list-item>
        <footer-link-list-item>Ссылка 2</footer-link-list-item>
        <footer-link-list-item>Ссылка 3</footer-link-list-item>
        <footer-link-list-item>Ссылка 4</footer-link-list-item>
      </footer-link-list>
    </base-card>
    <base-card is-no-shadow class="section-footer__contact-us-information">
      <template v-slot:card-title>
        <h6>{{$t('PORTAL.SECTION.FOOTER.ACTUAL.LINK.HEADERS.CONTACT')}}</h6>
      </template>
      <FooterBlockContacts/>
    </base-card>
  </base-card-list>
</template>

<script>
import BaseCard from '@/components/base/card/BaseCard'
import BaseCardList from '@/components/base/card/BaseCardList'
import FooterBlockContacts from '@/components/section/footer/components/FooterBlockContacts'
import FooterLinkList from '@/components/section/footer/components/FooterLinkList'
import FooterLinkListItem from '@/components/section/footer/components/FooterLinkListItem'

export default {
  name: 'SectionFooterActualLinkList',
  components: {
    FooterBlockContacts,
    FooterLinkListItem,
    FooterLinkList,
    BaseCardList,
    BaseCard
  }
}
</script>

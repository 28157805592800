<template>
 <ul>
   <slot></slot>
 </ul>
</template>

<script>
export default {
  name: 'FooterLinkList'
}
</script>
